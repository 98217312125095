* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}
body {
    height: 100%;
    overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}